html {
  height: 100%;
}

body {
  font-family: "Museo Sans", sans-serif;
  font-size: $base-font-size;
  background-color: $bg-dark;
  color: $text;
  min-height: 100%;
}

#root a {
  transition: 0.3s ease;
}

.main-wrapper {
  min-width: 0;
}

.wrapper {
  max-width: calc(1171px + 32px);
  width: 100%;
  padding: 32px 16px;
  margin-left: auto;
  margin-right: auto;

  @include for-desktop-up {
    padding: 80px 16px;
  }
}

.disabled-scroll {
  overflow-y: hidden;
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  white-space: nowrap;
}

.main-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: $white;

  @include for-desktop-up {
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
  }

  span {
    color: $text-yellow;
  }
}

* {
  tab-size: 4;
}
