@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.header {
  position: sticky;
  top: 0;
  left: 0;
  background: $bg-grey;
  min-height: $header-height-mobile;
  transition: background-color 0.225s ease;
  z-index: 999;

  @include for-desktop-up {
    min-height: $header-height;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    min-height: inherit;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__hamburger {
    @include for-desktop-up {
      display: none;
    }
  }

  &__logo {
    height: 44px;
  }
}
