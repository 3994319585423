@import '/src/styles/partials/mixins';
@import '/src/styles/partials/settings';

.paragraphWithReadMore{
  //span {
  //  display: none;
  //}
  //
  //&.text-full {
  //  span {
  //    display: inline;
  //  }
  //}


  //display: -webkit-box;
  //-webkit-line-clamp: 2;
  //-webkit-box-orient: vertical;
  //overflow: hidden;
  //text-overflow: ellipsis;
  //
  //&.text-full {
  //  display: block;
  //  -webkit-line-clamp: unset;
  //  -webkit-box-orient: unset;
  //  overflow: visible;
  //  text-overflow: unset;
  //  max-height: none;
  //}

  &__button {
    display: inline;
    font-family: "Inter", sans-serif;
    color: $yellow;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-left: 3px;
  }
}