@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.hero {
  background: $bg-grey;

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    gap: 26px;

    @include for-desktop-up {
      flex-direction: row;
    }
  }

  &__main-container {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include for-desktop-up {
      display: block;
      flex: 0 1 524px;
      text-align: left;
    }
  }

  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-transform: uppercase;

    @include for-desktop-up {
      text-align: left;
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;
    }

    span {
      color: $text-yellow;
    }
  }

  &__description {
    margin-top: 12px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: $text;
    text-align: center;

    @include for-desktop-up {
      text-align: left;
    }
  }

  &__button {
    margin-top: 24px;

    @include for-desktop-up {
      margin-top: 40px;
    }
  }

  &__picture {
    @include for-desktop-up {
      flex: 0 1 620px;
    }
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__available {
    max-width: 290px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    margin-top: 11px;

    span {
      display: block;
      flex: 100%;

      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: #333;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &__android {
    margin-top: 1px;
  }
}
