@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.features {
  position: relative;
  overflow: hidden;

  &__title{
    @media (max-width: 475px) {
      max-width: 288px;
    }
  }

  &:after {
    content: "Features";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    color: #1e1e1e;

    font-family: "Montserrat", sans-serif;
    font-size: 182px;
    font-weight: 700;
    line-height: 130px;
    letter-spacing: -2.64px;
    text-transform: uppercase;
    text-align: center;

    @include for-desktop-down {
      display: none;
    }

    @media (min-width: 1440px) {
      font-size: 264px;
      line-height: 180px;
    }
  }

  &__wrapper {
    overflow: hidden;
  }

  &__slider {
    margin-top: 40px;
    padding: 24px 0;
    //max-width: 100vh;

    @include for-desktop-down {
      margin-top: 24px;
      margin-left: 0;
      max-width: 288px;
      position: relative;
      box-sizing: border-box;
      overflow: visible;
      padding: 0;
    }

    @include for-desktop-up {
      background: #333333;
    }
  }

  &__slide-content {
    background: #333333;
    max-width: 925px;
    height: 460px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 26px 0 0;

    @include for-desktop-up {
      flex-direction: row;
      align-items: center;
      padding: 0;
      gap: 0;
      height: 560px;
    }
  }

  &__slide-bottom {
    padding: 0 24px;

    @include for-desktop-down {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 0 0 236px;
    }
  }

  &__slide {
    overflow: hidden;

    .counter {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      color: $yellow;

      @include for-desktop-down {
        display: none;
      }
    }

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      color: #fff;
      margin-bottom: 12px;

      @include for-desktop-up {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        margin: 16px 0;
      }
    }

    .description {
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 0.14px;

      @include for-desktop-up {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.16px;
      }
    }

    .button {
      margin-top: 40px;

      @include for-desktop-down {
        width: 100%;
        margin-top: 24px;
      }
    }

    .image {
      @include for-desktop-down {
        position: absolute;
        width: calc(100% - 16px);
        height: auto;
        bottom: -30%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__navigation {
    margin-top: 65px;
    justify-content: flex-start !important;
  }
}
