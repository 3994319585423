.policy-section {
  font-family: "Museo Sans", sans-serif;
  color: #fff;

  &__wrapper {
    position: relative;
    max-width: 962px;
    margin: 0 auto;
    padding: 14px 16px;

    @include for-desktop-up {
      padding: 56px 0;
    }
  }

  &__update {
    .policy-section & {
      font-size: 30px;
      font-weight: 700;
      line-height: 45px;
    }
  }

  &__title {
    align-items: flex-start;
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__title-compatibility {
    @media (max-width: 425px) {
      font-size: 38px;
      line-height: 58px;
    }
  }

  &__text {
    color: #fff;
    font-size: 14px;
    line-height: 21px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 16px;
      color: #fff;
    }

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 39px;
      margin-bottom: 24px;
    }

    h3 {
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      margin-top: 34px;
      margin-bottom: 16px;
      color: #fff;
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
    }

    p,
    ul,
    ol {
      color: #fff;
      margin-bottom: 24px;
      list-style: disc;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    ul,
    li {
      margin: 5px 0 5px 20px;
    }

    b {
      font-weight: 700;
      color: #fff;
    }

    a {
      color: #fff;
      transition: 0.3s ease;

      &:hover {
        color: $yellow;
      }
    }

    i {
      color: #fff;
    }

    .sub-title {
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      margin-bottom: 11px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__uppercase {
    text-transform: uppercase;
  }

  &__italic {
    font-style: italic;
  }

  &__underline {
    text-decoration: underline;
  }

  ol {
    list-style: auto;
    counter-reset: item;
    list-style: none;

    li {
      margin: 0.5em 0;
      counter-increment: item;
      display: flex;
      align-items: baseline;
      gap: 3px;

      &::before {
        content: counter(item) ". ";
        color: $text-yellow;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        text-align: right;
        flex: 0 0 26px;
      }
    }
  }

  ul {
    margin: 0;
    list-style: none;

    li {
      position: relative;

      &::before {
        content: "";
        background: $text-yellow;
        display: block;
        position: absolute;
        left: -15px;
        top: 8px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}

.underline {
  text-decoration: underline;
}

.definitions {
  .title {
    display: block;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  p {
    padding-left: 40px;
  }
}
