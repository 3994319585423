@import '/src/styles/partials/mixins';
@import '/src/styles/partials/settings';

.nav {
  text-align: center;
  position: fixed;
  top: $header-height-mobile;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  overflow: auto;
  background-color: transparent;
  padding: 8px 24px 20px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .225s ease;

  &.is-opened {
    opacity: 1;
    transform: translate(0, 0);
    pointer-events: auto;
    background-color: $bg-dark;
  }

  @include for-desktop-up {
    &.is-hide-desktop {
      display: none;
    }
  }

  @include for-desktop-up {
    flex: 100%;
    position: static;
    padding: 0;
    width: auto;
    opacity: 1;
    pointer-events: auto;
    transition: none;
    overflow: visible;
    box-shadow: none;
  }

  &-items {
    @include for-desktop-up {
      display: flex;
    }
  }

  &-item {
    position: relative;
    @include hover-supported {
      .nav-link {
        color: $button-color-hover;
      }
    }

    &.is-active {
      .nav-link {
        color: #63C1DB;
      }
    }
  }

  &-link {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: capitalize;
    color: #FFFFFF;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;

    @include for-desktop-up {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: $header-height-mobile;
      transition: color .225s ease;
    }

    @include for-tablet {
      padding: 0 20px;
    }

    @include focus-visible;
  }

  &-text {
    position: relative;
    white-space: nowrap;

    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      bottom: 2px;
      width: 100%;
      height: 1px;
      background-color: transparent;
      transition: background-color .225s ease;
    }
  }
}
