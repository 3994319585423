.link {
  font-size: 13px;
  line-height: 18px;
  color: #00c2ff;
  transition: color 0.225s ease;

  @include for-desktop-up {
    font-size: 16px;
    line-height: 24px;
  }

  @include hover-supported {
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }

  &:active {
    color: #00a3ff;
  }

  @include focus-visible;
}
