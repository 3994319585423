@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.page-404 {
  &__content {
    padding: 70px 0;

    @include for-desktop-up {
      padding: 150px 0;
    }
  }
}
