//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Montserrat:wght@700&display=swap');

/* museo-sans-100 */
@include font-face(normal, 100, "Museo Sans", "MuseoSansCyrl", false);

/* museo-sans-300 */
@include font-face(normal, 300, "Museo Sans", "MuseoSansCyrl", false);

/* museo-sans-500 */
@include font-face(normal, 500, "Museo Sans", "MuseoSansCyrl", false);

/* museo-sans-700 */
@include font-face(normal, 700, "Museo Sans", "MuseoSansCyrl", false);

/* Inter-500 */
@include font-face(normal, 500, "Inter", "Inter", false);

/* Montserrat-700 */
@include font-face(normal, 700, "Montserrat", "Montserrat", false);
