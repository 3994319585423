@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.get-started {
  background: $bg-grey;

  &__title {
    br {
      @include for-desktop-up {
        display: none;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px auto 0;
    max-width: 300px;

    @include for-desktop-up {
      margin-top: 40px;
      max-width: 370px;
    }
  }

  &__item {
    width: 100%;
    flex: 100%;
    display: flex;
    gap: 16px;
    padding-bottom: 24px;
    position: relative;
    overflow: hidden;

    &:last-child {
      &:before {
        display: none;
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 1px;
      height: 100%;
      background: #4d4d4d;
      top: 8px;
      left: 20px;
      z-index: 1;
    }

    @include for-desktop-up {
      padding-bottom: 32px;
    }

    .step {
      color: $yellow;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 4px;
    }

    .description {
      color: $text;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 0.14px;
      margin-top: 8px;

      br {
        @include for-desktop-down {
          display: none;
        }
      }
    }

    .icon_wrap {
      background: $bg-grey;
      height: 56px;
      flex: 0 0 40px;
      z-index: 2;
      padding: 8px 0;
    }

    .icon {
    }
  }

  &__button {
    margin-left: auto;
    margin-right: auto;
  }
}
