@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.footer {
  background: $bg-grey;

  &__logo {
    @include for-desktop-down {
      margin: 0 auto;
      display: block;
    }
  }

  &__wrapper {
    padding: 40px 16px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;

    @include for-desktop-down {
      max-width: 288px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      flex-direction: column;
      gap: 30px;
    }
  }

  &__content-item {
    display: block;
    margin: 24px 0;

    @include for-desktop-up {
      margin: 10px 0 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &.address {
      margin: 12px 0 0;
    }

    a {
      color: #fff;
      font-weight: 700;

      &:hover {
        color: $button-color-hover;
      }
    }

    strong {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__content-bottom {
    margin: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include for-desktop-up {
      flex-direction: row;
      justify-content: space-between;
    }

    @include for-desktop-down {
      max-width: 288px;
      margin-left: auto;
      margin-right: auto;
    }

    h4 {
      padding: 8px 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;

      @include for-desktop-down {
        text-align: center;
      }
    }
  }

  &__nav {

    h4 {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
    }
  }

  &__list {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    a {
      color: $text;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 0.14px;

      &:hover {
        color: $button-color-hover;
      }
    }
  }

  &__copy {
    color: #a2a2a2;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.14px;
  }

  &__payment-list {
    display: flex;
    gap: 13px;

    @include for-desktop-down {
      justify-content: center;
      flex-wrap: wrap;
    }

    figure {
      width: 49px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #fff;

      &.wide {
        width: 66px;
      }
    }
  }
}
