.reviews__slider {
  .swiper-wrapper {
    margin-bottom: 24px;

    @include for-desktop-up {
      margin-bottom: 40px;
    }
  }
}

.features__slider {
  .swiper-wrapper {
    @include for-desktop-down {
      margin-bottom: 24px;
    }
  }
}

.swiper-pagination {
  position: static;

  @include for-desktop-up {
    display: none;
  }
}

.swiper-pagination {
  padding: 8px;
  justify-content: center;
  align-items: center;

  @include for-desktop-down {
    display: flex;
  }
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #594711;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 23px;
  height: 23px;
  border: 1px solid #f2b600;
  background: transparent;
  position: relative;

  &:before {
    content: "";
    background: #f2b600;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
  }
}

.swiper-navigation {
  display: flex;
  justify-content: center;
  gap: 16px;

  @include for-desktop-down {
    display: none;
  }
}

.swiper-navigation-btn {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $yellow;
  cursor: pointer;
  color: #f2b603;
  transition: 0.3s ease;

  &.is-disabled {
    border-color: #9c9c9c;

    svg {
      color: #9c9c9c;
    }
  }
}

.swiper-navigation-icon {
  transition: 0.3s ease;

  &.next {
    transform: rotate(180deg);
  }
}
