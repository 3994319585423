.table {
  margin: 20px 0;

  @include for-desktop-down {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  table {
    width: 100%;

    td,
    th {
      border: 1px solid;
      text-align: center;
      vertical-align: middle;
    }
  }

  th {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-transform: uppercase;
    color: $text;
  }

  td,
  th {
    padding: 28px 10px;
  }

  td {
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.14px;
    color: $text;
    vertical-align: top;
  }

  &--principles {
    table {
      min-width: 970px;

      td,
      th {
        &:nth-child(1) {
          width: 5%;
        }

        &:nth-child(2) {
          width: 23.75%;
        }

        &:nth-child(3) {
          width: 23.75%;
        }

        &:nth-child(4) {
          width: 23.75%;
        }

        &:nth-child(5) {
          width: 23.75%;
        }
      }
    }
  }

  &--rights {
    table {
      min-width: 970px;
      table-layout: fixed;

      td,
      th {
        @include for-desktop-up {
        }
      }
    }
  }
}
