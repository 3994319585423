@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.reviews {
  background: $bg-grey;

  &__slider {
    margin-top: 40px;

    @include for-desktop-down {
      margin-top: 24px;
      max-width: 288px;
    }
  }

  &__slide {
    background: #333333;
    padding: 12px 12px 24px;
    max-width: 708px;
    height: auto !important;
    box-sizing: border-box;
    border-radius: 10px;

    @include for-desktop-up {
      border-radius: 0;
      padding: 40px;
      height: 340px !important;
    }

    .top {
      display: flex;
      gap: 12px;
      margin-bottom: 24px;

      @include for-desktop-up {
        margin-bottom: 32px;
        gap: 16px;
      }
    }

    .author {
      display: block;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
    }

    .date {
      color: #9c9c9c;
      display: block;
      font-size: 11px;
      font-weight: 600;
      line-height: 20px;
    }

    .title {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;

      @include for-desktop-up {
        line-height: 24px;
      }
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #9c9c9c;
      margin-top: 12px;

      @include for-desktop-up {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.16px;
      }
    }

    .icon {
      margin-top: 8px;

      @include for-desktop-up {
        margin-top: 10px;
      }
    }
  }
}
