// common typography settings
$base-font-size: 16px;

// header height settings
$header-height: 57px;
$header-height-mobile: 64px;

// colors settings
$white: #ffffff;
$bg-dark: #171717;
$bg-dark1: #181818;
$bg-grey: #1C1C1C;
$text: #E6E6E6;
$text-yellow: #F2B603;
$yellow: #F2B601;
$button-color: #F2B600;
$button-color-hover: #cca42b;

$path-to-static-fonts: '../fonts/';