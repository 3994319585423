@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.why {
  background: $bg-grey;

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 24px;

    @include for-desktop-up {
      flex-direction: row;
      margin-top: 40px;
      gap: 32px;
    }
  }

  &__item {
    background: $bg-dark1;
    max-width: 400px;
    width: 100%;
    flex: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 5px;

    @include for-desktop-up {
      min-height: 320px;
    }

    .title {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      text-align: center;

      @include for-desktop-up {
        margin-top: 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
      }

      br {
        @include for-desktop-down {
          display: none;
        }
      }
    }

    .description {
      text-align: center;
      margin-top: 12px;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    .image {
      margin: 13px 0;

      @include for-desktop-up {
        margin: 16px 0;
      }
    }

    .image-bottom {
      margin-top: 16px;
    }

    .button {
      margin-top: 16px;
    }
  }
}
